<template>
  <div>
    <div class="pb-5">
      <SmallNav />
      <div class="schedule">
        <div class="schdule_funding_btn">
          <div @click="navigateBack">
            <p><img src="../assets/images/safe.svg" alt="" /> Back</p>
          </div>
          <button class="btn" @click="navigateToAutoDebitHistory">
            Automatic Debit
            <img src="../assets/images/timer-icon.svg" alt="time" />
          </button>
        </div>
        <!-- <img src="../assets/images/safe.svg" alt="safe" /> -->
        <hr />
        <div class="container schedule_holder">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="">
                <!--  "bootstrap": "^4.5.3", -->
                <!--  "bootstrap": "^5.1.3", -->
                <!-- <div class="mb-4 text-justify d-none">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="scheduled"
                      value="option1"
                    />
                    <label class="form-check-label radio-label" for="scheduled"
                      >Setup a schedule</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="atwill"
                      value="option2"
                    />
                    <label class="form-check-label radio-label" for="atwill"
                      >Fund safe at will</label
                    >
                  </div>
                </div> -->

                <div>
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="date">Mode of Funding (Frequency)</label>
                          <select
                            class="form-control"
                            id="date"
                            v-model="modeofsafing"
                          >
                            <!-- @change="calculateDate" -->
                            <option selected disabled value=""
                              >Select Day, Week or Month</option
                            >
                            <option
                              v-for="(item, index) in modeOfSafeDebit"
                              :key="index"
                              :value="item.id"
                              >{{ item.name }}</option
                            >
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="enddate" class="label_check"
                            >Amount
                            <span v-if="modeofsafing === 1"> (Daily)</span>
                            <span v-if="modeofsafing === 2"> (Weekly)</span>
                            <span v-if="modeofsafing === 3"> (Monthly)</span>
                          </label>
                          <input
                            onfocus="(this.type='text')"
                            type="number"
                            class="form-control"
                            v-model="amountFormatted"
                            id="amount"
                            placeholder="Debit Amount"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="startdate">Start Date/Time</label>
                          <input
                            type="date"
                            class="form-control"
                            id="startdate"
                            v-model="startdate"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="enddate">End Date/Time</label>
                          <input
                            :disabled="startdate === '' || modeofsafing === ''"
                            type="date"
                            class="form-control"
                            id="enddate"
                            v-model="enddate"
                            @change="calculateDate"
                          />
                        </div>
                      </div>
                    </div>

                    <p class="note">
                      Note: Amount is the debit done either daily, weekly, or
                      monthly based on the mode of funding you selected.
                    </p>

                    <!-- <div class="row d-none">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="installment">Daily Installment</label>
                          <input
                            type="number"
                            class="form-control"
                            id="installment"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="target">Target by Jan 22, 2023</label>
                          <p class="form-control target_input" id="target">
                            &#8358;00,000,000.00
                          </p>
                        </div>
                      </div>
                    </div> -->
                  </form>
                  <!-- <div class="view_summary_btn">
                    <button class="btn">VIEW SUMMARY</button>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="summary">
                <div>
                  <h6 class="summary_text" v-if="enddate != ''">SUMMARY</h6>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <tbody class="summary_table">
                        <tr v-if="modeofsafing == 1">
                          <td>Funding type</td>
                          <td class="bold-text">Daily</td>
                        </tr>
                        <tr v-if="modeofsafing == 2">
                          <td>Funding type</td>
                          <td class="bold-text">Weekly</td>
                        </tr>
                        <tr v-if="modeofsafing == 3">
                          <td>Funding type</td>
                          <td class="bold-text">Monthly</td>
                        </tr>
                        <tr v-if="enddate != '' && amount != ''">
                          <td>Amount</td>
                          <td class="bold-text">
                            &#8358;{{ Number(amount).toLocaleString() }}
                          </td>
                        </tr>

                        <!-- <tr v-if="modeofsafing == 2">
                          <td>Savable Amount(Based on date)</td>
                          <td class="bold-text">
                            &#8358;{{
                              Number(amount * weekly).toLocaleString()
                            }}
                          </td>
                        </tr> -->

                        <tr v-if="startdate != ''">
                          <td>Start date</td>
                          <td class="bold-text">{{ startdate | moment }}</td>
                        </tr>
                        <tr v-if="enddate != ''">
                          <td>End date</td>
                          <td class="bold-text">{{ enddate | moment }}</td>
                        </tr>
                        <tr
                          v-if="
                            amount != '' && monthly >= 1 && modeofsafing == 3
                          "
                        >
                          <td>Expected Amount (Based on date)</td>
                          <td class="bold-text">
                            &#8358;{{
                              Number(amount * monthly).toLocaleString()
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="amount != '' && weekly > 0 && modeofsafing == 2"
                        >
                          <td>Expected Amount (Based on date)</td>
                          <td class="bold-text">
                            &#8358;{{
                              Number(amount * weekly).toLocaleString()
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="amount != '' && daily >= 3 && modeofsafing == 1"
                        >
                          <td>Expected Amount (Based on date)</td>
                          <td class="bold-text">
                            &#8358;{{ Number(amount * daily).toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- <button class="btn" @click="paymentModalFund">Click</button> -->
            </div>
          </div>
          <div class="submit_btn">
            <button @click="initializePaystack" class="btn" :disabled="loader">
              SUBMIT
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loader"
              ></i>
            </button>
          </div>

          <!-- <component class="more-content" :is="PaymentModal"></component> -->
          <PaymentModal
            @paystackReference="dataFromChild($event)"
            :dataForPayment="modalData"
            v-model="paymentM"
          ></PaymentModal>
          <!-- <component class="" :header="headerCaption" :is="PaymentModal"></component> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import PaymentModal from "./PaymentModal.vue";
import $ from "jquery";
import moment from "moment";
import Vue from "vue";

export default {
  name: "SafeSchedule",
  components: {
    SmallNav,
    PaymentModal
    // Footer,
  },
  data() {
    return {
      loader: false,
      user: "",
      modeOfSafeDebit: "",
      modeofsafing: "",
      startdate: "",
      enddate: "",
      amount: "",
      amountFormatted: "",
      paymentM: "",
      modalData: {},
      weekly: "",
      monthly: "",
      daily: "",
      savableAmount: "",
      reference: ""
    };
  },
  watch: {
    amountFormatted: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.amountFormatted = result));
      this.amount = this.amountFormatted.split(",").join("");
    }
  },
  created() {
    this.$root.$refs.A = this;
    this.$root.$refs.C = this;
    // this.modalData.headerCaption = "Automatic Safe Debit";
  },
  mounted() {
    this.fetchModeOfDebitForSafe();
    this.getCurrentDay();
  },
  methods: {
    navigateBack() {
      this.$router.go(-1);
    },
    navigateToAutoDebitHistory() {
      return this.$router.push({
        path: `/dashboard/autodebithistory`
      });
    },
    dataFromChild(data) {
      // console.log("Data from child", data);
      this.reference = data;
      // this.setupScheduleFinally();
    },
    paymentModalFund: function() {
      // this.loader = true;
      // console.log("Open payment");
      // this.paymentM = true;
      this.modalData.amount = 50;
      this.modalData.payableAmount = this.amount;
      this.modalData.subtitle = "Card Autorization (₦50)";
      this.modalData.paymentType = "autodebitsafe";
      this.modalData.headerCaption = "Automatic Safe Debit";
      this.modalData.headerBackground = "#0033ea";
      // this.modalData.url = "";
      this.$root.$refs.A.openPaymentOptionsModal();
    },
    fetchModeOfDebitForSafe() {
      Api.getRequest(`fetchmodeofsafingforprojects`)
        .then(res => {
          // console.log("Mode of safe debit", res);
          if (res.data.success) {
            this.modeOfSafeDebit = res.data.modes;
          } else {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    initializePaystack() {
      this.loader = true;
      if (
        this.startdate === "" ||
        this.enddate === "" ||
        this.amount === "" ||
        this.modeofsafing === ""
      ) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "All fields are required");
      }
      const startdate = Date.parse(this.enddate);
      const enddate = Date.parse(this.startdate);
      if (startdate <= enddate) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "Start date should be less than End date"
        );
      }
      if (this.modeofsafing == 3 && this.monthly < 1) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than one month from today"
        );
      }
      if (this.modeofsafing == 2 && this.weekly === 0) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than one week from today"
        );
      }
      if (this.modeofsafing == 1 && this.daily < 3) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than three days from today"
        );
      } else {
        this.paymentModalFund();
      }
    },

    async scheduleSafeFunding() {
      this.loader = true;
      // console.log("Schedule Id Again", this.scheduleid);
      if (
        this.startdate === "" ||
        this.enddate === "" ||
        this.amount === "" ||
        this.modeofsafing === ""
      ) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "All fields are required");
      } else {
        try {
          await Api.postRequest(`safescheduleandtokenize`, {
            amount: Number(this.amount),
            modeofsafing: this.modeofsafing,
            startdate: this.startdate,
            enddate: this.enddate,
            reference: this.reference
          })
            .then(res => {
              console.log("Schedule done", res);
              this.loader = false;
              if (res.data.success) {
                // console.log("Done");
                this.loader = false;
                this.sendUserEmailAfterPayment();
                this.$store.commit("setApiSuccess", "Automatic funding done");
                this.$router.push({
                  path: `/dashboard/safe`
                });
              }
              if (res.data.error) {
                this.loader = false;
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log(err);
              this.loader = false;
            });
        } finally {
          console.log("closed");
        }
      }
    },

    async sendUserEmailAfterPayment() {
      try {
        await Api.postRequest(`publiccreditalertemail`, {})
          .then(res => {})
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    closedPaystack() {
      this.loader = false;
    },

    getCurrentDay() {
      // This function disable previous date in date picker
      const today = new Date().toISOString().split("T")[0];
      document.getElementById("startdate").setAttribute("min", today);
      document.getElementById("enddate").setAttribute("min", today);

      // const current = new Date();
      // current.setMonth(current.getMonth() + 3);
      // const x = new Date(current).toISOString().split("T")[0];
      // document.getElementById("enddate").setAttribute("max", x);
    },
    calculateDate() {
      // Weekly pick
      const oneweek = 1000 * 60 * 60 * 24 * 7;
      const d1 = new Date(this.startdate).getTime();
      const d2 = new Date(this.enddate).getTime();
      const difference = Math.abs(d2 - d1);
      this.weekly = Math.floor(difference / oneweek + 1);
      // console.log("weeks", Math.floor(difference / oneweek), this.weekly);
      // return Math.floor(difference / oneweek);

      // Daily pick
      const day = 1000 * 60 * 60 * 24;
      const date1 = new Date(this.startdate).getTime();
      const date2 = new Date(this.enddate).getTime();
      const difference2 = Math.abs(date2 - date1);
      this.daily = Math.floor(difference2 / day + 1);
      // console.log("Days", Math.floor(difference2 / day), this.daily);
      // return Math.floor(difference_ms / ONE_WEEK);

      // Monthly pick
      let months;
      months =
        (new Date(this.enddate).getYear() -
          new Date(this.startdate).getYear()) *
        12;
      months -= new Date(this.startdate).getMonth();
      months += new Date(this.enddate).getMonth();
      this.monthly = months <= 0 ? 0 : months + 1;
      // console.log("Months", this.monthly);

      if (this.modeofsafing == 3 && this.monthly < 1) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than one month from today"
        );
      }
      if (this.modeofsafing == 2 && this.weekly === 0) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than one week from today"
        );
      }
      if (this.modeofsafing == 1 && this.daily < 3) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "End date must not be less than three days from today"
        );
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
      // if you want to show default time
      // return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$limeGreen: #3ec250;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

// Lato fonts

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

// Raleway font
@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"),
    url("../assets/fonts/Raleway/static/Raleway-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: local("Raleway-Regular"),
    url("../assets/fonts/Raleway/static/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-italic";
  src: local("Raleway-italic"),
    url("../assets/fonts/Raleway/static/Raleway-Italic.ttf") format("truetype");
}

.schdule_funding_btn {
  padding: 20px 0 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  button {
    background: $primary;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $secondary;
    box-shadow: none;
    outline: none;
    img {
      padding-left: 5px;
    }
  }

  p {
    img {
      background: #3059eb;
      border-radius: 3px;
      padding: 5px;
    }
    font-family: Gotham;
    margin-right: 20px;
    margin-bottom: 0;
    color: $primary;
    cursor: pointer;
  }
}
.schedule {
  background: $secondary;
  margin: 20px;
  border-radius: 10px;
}
.schedule_holder {
  padding: 15px;
}
.label_check {
  span {
    font-family: Gotham;
    font-size: 12px;
    padding-left: 2px;
  }
}
input[type="radio"] {
  // transform:scale(1.5);
  width: 20px;
  height: 20px;
}

input[type="select"],
inpute[type="text"] {
  height: 39.68px;
  border: 0.7px solid #e5e5e5;
  border-radius: 5px;
}
.radio-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: $black;
  display: flex;
  box-shadow: none;
  outline: 0;
}
.form-group > label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  color: $black;
}
.form-group {
  select,
  input {
    height: 39.68px;
    border: 0.7px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $black;
  }
}

.target_input {
  background: #d9d9d9;
  border: 0.7px solid #e5e5e5;
  color: $black;
  font-weight: 400;
  // font-size: 20px;
  text-align: left;
  margin-bottom: 0;
}
.summary_text {
  color: $black;
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  font-weight: bold;
}
.summary_table {
  text-align: left;
  td {
    border-top: none;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $black;
  }
  .bold-text {
    font-weight: bold;
  }
}

.view_summary_btn {
  button {
    background: $primary;
    color: $secondary;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
  }
}

.view_summary_btn {
  display: flex;
  justify-content: left;
  button {
    background: $primary;
    color: $secondary;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    width: 170.61px;
    // float: left;
    margin-top: 30px;
  }
}

.submit_btn {
  display: flex;
  justify-content: right;
  button {
    background: $primary;
    color: $secondary;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    width: 170.61px;
    // float: right;
    margin-top: 30px;
    box-shadow: none;
    outline: 0;
  }
}

.note {
  font-family: Gotham;
  font-weight: normal;
  font-size: 11px;
  text-align: left;
  color: rgb(235, 71, 71);
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #494747;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #494747;
}

::placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #494747;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 599px) {
  .summary {
    margin-top: 30px;
  }
  .view_summary_btn {
    justify-content: center;
  }
  .submit_btn {
    justify-content: center;
  }
}
</style>
